.a {
    background-color: #fff;
    border-color: #d3e0e9;
}

.nav__betslip.boost-message-count {
    height: 36px !important;
    width: 36px !important;

}
.time-box__time {
    display: inline-block;
    height: 3.41666667rem;
    width: 3.41666667rem;
    background: #353A45;
    border-radius: 0.33333333rem;
    font-size: 3.166667rem;
    line-height: 3.41666667rem;
    text-align: center;
    margin-right: 0.66666667rem;
    position: relative;
    z-index: 10;
}
.counter-cashout.time-box__time::after{
    content: '';
    display: block;
    font-size: 0;
    line-height: 0;
    height: 1px;
    width: 100%;
    background: #1B1E25;
    position: absolute;
    top: 1.9rem;
    z-index: 11; 
}
.counter-cashout.time-box__time{
    display: inline-block;
    height: 3.41666667rem;
    width: 3.61666667rem;
    background: #353A45;
    border-radius: 0.33333333rem;
    font-size: 3.066667rem;
    line-height: 3.41666667rem;
    text-align: center;
    /* margin-right: 0.66666667rem; */
    position: relative;
    z-index: 10;
}
.time-box__time::after {
    content: '';
    display: block;
    font-size: 0;
    line-height: 0;
    height: 1px;
    width: 89%;
    background: #1B1E25;
    position: absolute;
    top: 2.5rem;
    z-index: 11;
}
.selected-filters__item {
    margin-right: 5px;
    margin-bottom: 0px;
    font-size: var(--font-small);
    border: none;
    background: var(--light-gray);
    color: var(--light);
    border-radius: 25px;
    padding: 3px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    opacity:0.8;
    position: relative;
}
.close-filter{
    font-size:9px;
}
.sport-styling{
    opacity:0.7;
    font-size:var(--fon-size-large-variation1);
}

#reason_for_self_exclusion {
    min-height: 8rem;
    background: var(--bet-history);
    color: var(--light);
}


.error-message {
    color: red;
    font-size: 14px;
}

#reason_for_self_exclusion::placeholder {
    color: var(--light);
}
#place_bet_button{
    min-height:3.7rem;

}
#place_bet_button_nare {
    min-height: 3.7rem;
}

#share-btn{
    min-height:3.5rem;
}
.css-13cymwt-control{
    background: var(--bet-history) !important;
    color: var(--light) !important;
    border: thin solid var(--bet-history) !important;
}
.css-13cymwt-control::placeholder{
    color:var(--light) !important
}
.css-13cymwt-control input{
    background: inherit!important;
    min-width:inherit!important;
}
.css-1dimb5e-singleValue{
    color:var(--light) !important
}
.css-qbdosj-Input input{
    background: inherit!important;
    min-width:inherit!important;
    border:none!important;
    color:var(--light) !important;
}
.Patapesa-button-bg{
    background: var(--Patapesa-button-login) !important;
    font-weight:700!important;
}
.css-qbdosj-Input input::placeholder{
    color:var(--light) !important;
}

.hot-alert-badge {
    right: -16px;
    margin-top: 5px !important;
    padding: 0px 5px;
    border-radius: 5px;
    position: absolute;
    font-size: var(--font-tiny);
    font-weight: bolder;
    text-transform: none;
    padding-top: 1px;
    color: var(--always-white);
    background: var(--red);
    background-size: 35px 16px;
    width: 28px;
    height: 13px;
    text-align: center;
    top: -7px;
    flex-direction: column-reverse;
    display: flex;
}

/* general overrides */
.b .c {
    line-height: 1.4;
}

.d .close {
    margin-top: 4px;
}


.bet-body::-webkit-scrollbar {
    width: 1em;
}

.down-arrow {
    float: right;
    border: none !important;
    width: 16px !important;
    padding-top: 5px !important;
}

.bet-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--Patapesa-primary);
}

.bet-body::-webkit-scrollbar-thumb {
    background-color: #5f8c04;
    outline: 1px solid #5f8c04;
}

/* navbar */
.app-navbar {
    -webkit-font-smoothing: antialiased;
}

.app-navbar .form-control {
    box-shadow: none;
}

.app-navbar .e img {
    max-width: 215px;
    display: none;
}

.app-navbar .f {
    max-height: inherit;
}

.app-navbar .f:after {
    height: 100vh;
}

.app-navbar .form-control {
    border: 1px solid #636363;
    /* background: rgba; */
    border-radius: 3px !important;
    /* height: 31px; */
    font-weight: 100;
}

.g {
    margin-left: -12px;
    padding-bottom: 10px !important;
}

.betslip-error {
    display: none;
}

.betslip-success {
    display: none;
}

.g .h:before {
    font-size: 20px;
}

@media (min-width: 768px) {
    .app-navbar .f:after {
        height: auto;
    }
}

@media (max-width: 768px) {
    .i {
        margin-top: -1px;
    }
}

.betj {
    background: #fbfbfb;
    overflow: auto;
    clear: both;
}

.betj .col-sm-12, .betj .col-sm-6 {
    padding: 0;
}


/* misc */
.j {
    max-width: 280px;
    margin-top: -60px;
}

.k {
    margin-right: 10px;
}

.l {
    width: 40%;
    display: inline-block;
}

.l img {
    width: 100%;
}

.pick {
    cursor: copy;
    cursor: pointer;
    background: #F3F3F3;
}

.betslip input[type="number"] {

}

.bet-stake-input {
    width: 100%;
    height: 3.5rem;
    /* border-top-left-radius: 0px!important; */
    /* border-bottom-left-radius: 0px!important; */
}

.box-shadow-table-submit-form {
    /* box-shadow: 10px 10px 10px 10px var(--Patapesa-input); */
    padding: 5px;
    border-radius: 10px;
    border: thin solid var(--Patapesa-input);
    opacity:0.9;
}

.pick-user-match {
    transform: scale(1.5);
    color: var(--lite-top-color);
}

.slip-message-alert {
    height: 4rem;
    display: inline-table;
    white-space: normal !important;
    line-height: 15px !important;
    position: fixed !important;
    top: var(--slip-message-alert);
    background: var(--alert-slip-color);
    color: var(--green);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 21px;
    border-radius: 6px;
    margin-bottom: 5px;
    z-index:100;
}

.slip-message-alert.valid{
    background: var(--alert-slip-color);
    color: var(--green);
}
.slip-message-alert.invalid{
    background: var(--validation-fail-color) !important;
    color: var(--green);
}
.slip-message-alert:empty {
    display: none;
}

#odd-change-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#odd-change-text2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slip-clear-all {
    padding-right: 8px;
}

.odd-change-position .odds-change-box {
    /* padding-left: 0px!important; */
    /*width: 16%;*/
    /*padding: 13px;*/
}

#betting input {
    color: var(--light);
    padding: 3px;
    border-radius: 5.2px;
    text-align: end;
    padding-right: 5px;
}

.betslip form {
    margin-top: 10px;
    line-height: 1.3;
    border-radius: 5px;
    /* padding: 8px; */
    box-sizing: border-box;
    line-height: 0.8;
    background: var(--Patapesa-input);
}

.container-styling-input-placebet {
    border-radius: 10px;

}

.bg-input-placebet {
    background: var(--Patapesa-input);
}

.bg-input-placebet {
    height: 3.5rem;
    font-weight: 700;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* background: var(--Patapesa-primary); */
    padding-right: 10px;
}

#quick-login a {
    color: #fff;
    background: #c91d56;
    padding: 10px 10px;
}

#matches img {
    width: 60px;
}

.zero-padding {
    padding: 0 !important;
}

.betslip td, .betslip th {
    padding: 3px 5px;
}

#quick-login {
    background: #f2f2f2;
    padding: 8px 8px 10px;
    display: none;
    overflow: auto;
    width: 100%;
    /* color: #000; */
    font-weight: bold;
}

#quick-login .form-control:focus {
    background-color: #fff;
}

#quick-login p {
    color: #333333;
    margin-bottom: 6px;

}

a
.odds button {
    border: none;
    padding: 0px;
}

h4.inline-block {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
}

.side-icon {
    padding: 0 !important;
    border: 1px solid var(--variant2);
    border-radius: 100px;
}

.topl {
    padding: 2px 10px;
    color: var(--light) !important;
    opacity: 08;
}


.picked, .match-detail .picked {
    background: var(--picked) !important;
    color: var(--dark) !important;
    border: none;
    font-weight: normal !important;
}

.match-detail .picked {
    background: var(--secondary) !important;
    color: var(--dark) !important;
    border: none;
    font-weight: normal !important;
}

.theodds {
    font-size: 14px;
    font-weight: 400;
}

.picked .theodds {
    color: var(--dark);
    font-weight: bold;
}

.betslip {
    /*background: #1c313b;*/
    background: var(--Patapesa-primary);
    /*padding: 5px;*/
    padding-top: 1px;
    font-size: 14px;
    opacity: 1;
}

.tab-content > .active, .pill-content > .active {
    display: block;
}

.bet-option-list {
    list-style: none;
    margin-top: 0px;
    padding-right: 0;
    padding-left: 0;
}

li.bet-option {

}

.bet-option {
    width: 94%;
    margin-left: auto;
}


.slip-bg {
    background: linear-gradient(45deg, var(--slip-bg-color-1), var(--slip-bg-color-2));
    position: relative;
}

.bet-option-list li {
    float: right;
}

.bet-option-list li {
    padding-bottom: 3px !important;
    padding-top: 3px !important;
    /* background: var(--betslip-list); */
    color: var(--light);
    padding: 4px;
    text-shadow: none;
    /* border-bottom: 1px solid var(--Patapesa-primary); */
    /*overflow: hidden;*/
    line-height: 1.6;
    border-radius: 0px;
    display: flex;
    align-items: center;
}

.bet-option-list li:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.bet-option-list li:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

/*.bet-cancel {*/
/*    float: left;*/
/*    width: 10%;*/
/*    position: absolute;*/
/*    right: -12px;*/
/*    !* border-radius: 36px; *!*/
/*}*/
.bet-cancel {
    margin: auto;
    width: 15%;
    padding: 9px;
}

.bet-align-left.nare-boost-color {
    color: var(--lite-top-color) !important;
    /*font-weight: 800;*/
}

.show-values-betslip {
    font-size: 14px;
    padding-left: 10px;
}

.boost-betslip {
    font-size: 12.5px;
    /*padding-left: 11px;*/
}

.bet-align-right.nare-boost-color {
    color: var(--lite-top-color) !important;
}

.bet-cancel input {
    border-radius: 10px !important;
    border-bottom: var(--transparent);
}

.bet-cancel input:focus {
    border: none !important;
}

.slip-bg {
    border-bottom: thin solid var(--slip-separator-color) !important;
    height: 7.9rem;
}

.picks-user-slip {
    color: darkgrey;
    font-size: 11px !important;

}

.time-slip-value {
    font-size: 10px !important;
    opacity: 0.8;
    color: lightgrey;
}

.slip-bg:last-of-type {
    border-bottom: none !important;

}

.slip-bg:first-of-type {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.slip-bg:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.width-slip-item-container {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis " [..]";
    margin: auto;
}

.bet-cancel div {
    height: 25px;
    width: 25px;
    border-radius: 50% !important;
    font-size: 8px;
    font-weight: bolder !important;
    background: var(--btn-color) !important;
    margin: auto;
    display: flex;
}
.bet-cancel div input {
    font-weight: bolder !important;
    background: var(--btn-color) !important;
    border: none!important;
   
    border-radius: 0px!important;
    margin: auto;
}

.bet-align-left {
    text-align: left;
}

.bet-align-right {
    text-align: right;
}

.capitalize {
    text-transform: capitalize;
}

.bet-body .clearfix {
    float: left;
    margin: 0;
}

.bet-value {
    float: left;
    font-size: 13px;
    font-weight: 500;
}

.bet-pick {
    float: left;
    margin-top: 5px;
    width: 100%;
    color: #101b25;
}

.pay-method label {
    margin-bottom: 0;
    /* font-size: 12px; */
    margin-top: -8px;
}

.et.label {
    float: left;
    /*opacity: ;*/
    margin-left: 9px;
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0.5;
}

.bet-table a {
    color: #26408A;
    font-weight: bold;
    display: inline-block;
}

.team-info-slip-list {
    float: left;
    width: auto;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.team-info-slip-list-kiron {
    float: left;
    width: auto;
    font-weight: bold;
    /*display: flex;*/
    align-items: center;
}

.bet-odd {
    float: right;
    color: var(--Patapesa-button-login);
    font-size: 11px;
    opacity: 0.9;
    padding-right: 10px;
    margin: auto;
    font-weight: bolder;
}

.bet-type {
    font-size: 12px
}

.place-bet-btn {
    background: #4c2a3e;
    color: #fff;
    border: none;
    padding: 5px 10px;
    font-size: 11px;
}

.jp-matches .events-odd {
    background: #0c153b;
    text-transform: uppercase;
    color: #fff;
    padding: 10px;
}

.place-bet-btn.primary, .place_bet_button, #place_bet_button {
    background: var(--light);
    color: #000;
    border-radius: 0.7rem !important;
}

.remove_expired_btn, #remove_expired_btn{
    background: #ffc428 !important;
    border-radius: 7px!important;
}

#jp-nare-pick-button {
    color: #000000 !important;
    background-color: #fbd702 !important;
    border-radius: 6px !important;
}

#jp-nare-pick-button:hover {
    color: orangered !important;

}

.social-icons {
    float: right;
    padding-top: 10px;
}

.social-icons li {
    display: inline;
    float: left;
    width: 32px;
    height: 32px;
    margin-right: 6px;
    display: block !important;
}

.social-icons li i {
    font-size: 24px;
    color: #FFF;
    text-align: center;
    line-height: 24px;

}

.main-heading-1 {
    font-size: 16px !important;
    display: inline-block;
    font-weight: 600;
    white-space: nowrap;
}

.main-heading-2 {
    font-size: 16px !important;
    display: block;
    font-weight: 500;
    margin: 0;
}


.payment-form input[type=number], .payment-form select {
    padding: 8px 10px;
    border-radius: 0;
    width: 100%;
    display: block;
    border: 1px solid #333;
}

.payment-form select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.payment-form input[type=number] {
}

.payment-tabs ul {
    height: 70px;

}

.payment-tabs ul, .payment-tabs li {
    margin: 0;
    padding: 0;
}

.pament-tabs ul {
    width: 100%;
}

.payment-tabs ul > li {
    float: left;
    display: inline;
}

.payment-tabs ul > li > a {
    display: block;
    text-decoration: none;
    width: 80px;
    height: 80px;
    padding: 2px 10px 2px 0;
}

.payment-tabs ul > li > a > img {
    width: 100%;
}

.payment-tabs-container {
    padding-top: 10px;
    clear: both;
}

.payment-tabs-container .payment-tab {
    display: none;
}

.react-loading, .react-loading span {
    width: 100%;
    --base-color: #2b3640;
    --highlight-color: #404f5f;
    --animation-duration: 3.2s;
}

.react-loading-image, .react-loading-image span {
    background: #212931;
    --base-color: #2b3640;
    --highlight-color: #404f5f;
    width: fit-content;
    float: left;
}

.react-loading-skeleton {
}

.colored-circle {
    display: inline-block;
    margin-left: 0px;
    margin-right: 5px;
    margin-bottom: -2px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: #18242f;
    height: 7px;
    width: 7px;
    opacity: 1;
}

.register-label {
    color: #000000;
    text-decoration: none;
}

.verify-label {
    background: green !important;
    text-decoration: none;
}

.caret {
    width: 0;
    height: 0;
    display: inline-block;
    border: 5px solid transparent;
}

.caret.down {
    border-top-color: orange;
}

.caret.up {
    border-bottom-color: green;
}

#jp-nare-pick-button {
    color: #000000 !important;
    background-color: #fbd702 !important;
    border-radius: 6px !important;
}

#jp-nare-pick-button:hover {
    color: orangered !important;
}

.market-groups-container {
    display: flex;
    margin-bottom: 5px;
    min-height: 3rem;
    overflow-x: scroll !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    position: sticky;
    z-index: 2001;
}

.market-groups-container::-webkit-scrollbar {
    display: none !important;
}

.market-group-pill {
    outline: none !important;
    border-radius: 15px !important;
    background: var(--Patapesa-odds-body);
    margin: 0px 4px;
    padding: 10px 5px;
    cursor: pointer;
    border: 0.5px solid var(--Patapesa-odds-body);
}


.market-group-pill.active-group {
    background: var(--picked) !important;
    color: var(--black) !important;
}
.promo-inactive {
    opacity: 0.4 !important;
}